// src/pages/Services.js
import React from 'react';
import { Link } from 'react-router-dom';
import { CodeIcon, ServerIcon, CloudIcon, ChartBarIcon } from '@heroicons/react/outline';

const services = [
  {
    title: "Custom Software Development",
    description: "We specialize in creating tailored software solutions that address your unique business challenges and streamline operations.",
    icon: CodeIcon,
    details: [
      "Bespoke application development for desktop, web, and mobile platforms",
      "Legacy system modernization and integration",
      "Scalable and secure software architecture design",
      "Agile development methodologies for faster time-to-market",
      "Continuous integration and deployment (CI/CD) pipelines",
      "Quality assurance and testing services"
    ]
  },
  {
    title: "Managed IT Services",
    description: "Our comprehensive managed IT services ensure your technology infrastructure runs smoothly, allowing you to focus on your core business activities.",
    icon: ServerIcon,
    details: [
      "24/7 network monitoring and management",
      "Proactive maintenance and issue resolution",
      "Data backup and disaster recovery solutions",
      "IT help desk support for your team",
      "Software updates and patch management",
      "IT asset management and optimization"
    ]
  },
  {
    title: "Cloud Services & Solutions",
    description: "Leverage the power of cloud technology to enhance flexibility, scalability, and security for your business operations.",
    icon: CloudIcon,
    details: [
      "Cloud migration strategy and implementation",
      "Multi-cloud and hybrid cloud solutions",
      "Cloud-native application development",
      "Software as a Service (SaaS) implementations",
      "Cloud security and compliance management",
      "Performance optimization and cost management"
    ]
  },
  {
    title: "IT Consulting & Strategy",
    description: "Our expert IT consulting services help you make informed decisions about your technology investments and strategy.",
    icon: ChartBarIcon,
    details: [
      "IT infrastructure assessment and optimization",
      "Digital transformation roadmap development",
      "Cybersecurity strategy and risk management",
      "Technology stack evaluation and recommendations",
      "IT budget planning and cost optimization",
      "Compliance and regulatory guidance"
    ]
  }
];

function Services() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-indigo-900">IT Services in Reno & Denver</h1>
      <p className="mb-8">
        At Boachie Family, we offer a comprehensive suite of IT services designed to elevate your business operations in Reno, Denver, and beyond. Our expertise spans across multiple areas, providing you with the tools and support needed to thrive in today's digital landscape.
      </p>

      {services.map((service, index) => (
        <section key={index} className="bg-white shadow-lg rounded-lg p-8 mb-8">
          <div className="flex items-center mb-4">
            <service.icon className="h-8 w-8 text-indigo-600 mr-4" />
            <h2 className="text-3xl font-semibold text-indigo-800">{service.title}</h2>
          </div>
          <p className="mb-4">{service.description}</p>
          <ul className="list-disc pl-5 space-y-2">
            {service.details.map((detail, detailIndex) => (
              <li key={detailIndex}>{detail}</li>
            ))}
          </ul>
        </section>
      ))}

      <div className="bg-gray-100 p-6 rounded-lg">
        <p className="mb-4">
          At Boachie Family, we're committed to delivering not just services, but transformative IT solutions that propel your business forward. Our team of skilled professionals brings a wealth of experience and cutting-edge expertise to every project, ensuring that you receive the highest quality of service tailored to your unique needs.
        </p>
        <p>
          Ready to elevate your business with our custom IT solutions? {' '}
          <Link to="/submit-request" className="text-indigo-600 hover:text-indigo-800 font-semibold">
            Submit a request today
          </Link>
          {' '} to discuss how we can help you achieve your technology goals in Reno, Denver, or anywhere in the United States.
        </p>
      </div>
    </div>
  );
}

export default Services;