// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-indigo-800 text-white py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
            <ul>
              <li><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">IT Services</Link></li>
              <li><Link to="/about" className="hover:text-gray-300">About Us</Link></li>
              <li><Link to="/submit-request" className="hover:text-gray-300">Submit a Request</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-2">Contact Us</h3>
            <p>Reno Office: Reno/Sparks</p>
            <p>Denver Office: Denver Area</p>
            <a href="mailto:services@boachiefamily.net" className="hover:text-gray-300 underline">Email: services@boachiefamily.net</a>
            <p>Phone: N/A</p>
          </div>
          <div className="w-full md:w-1/3">
            <h3 className="text-lg font-semibold mb-2">Our Services</h3>
            <ul>
              <li><Link to="/services" className="hover:text-gray-300">Custom Software Development</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">Managed IT Services</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">Cloud Services</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">Web Development</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">IT Consulting</Link></li>
              <li><Link to="/services" className="hover:text-gray-300">Cybersecurity Solutions</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 text-center">
          © 2024 Boachie Family. All rights reserved. | Custom Software Solutions & IT Services
        </div>
      </div>
    </footer>
  );
}

export default Footer;