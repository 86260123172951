// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { CodeIcon, ServerIcon, CloudIcon, GlobeAltIcon, ChipIcon, ShieldCheckIcon } from '@heroicons/react/outline';

const services = [
  {
    title: "Custom Software Development",
    description: "Tailored software solutions designed to address your unique business challenges and streamline operations in wherever it may be.",
    icon: CodeIcon
  },
  {
    title: "IT Consulting & Managed Services",
    description: "Expert guidance to optimize your IT infrastructure, enhance security, and drive digital transformation.",
    icon: ServerIcon
  },
  {
    title: "Cloud Services & Web Development",
    description: "Comprehensive cloud solutions and responsive web development services for businesses in Reno and Denver.",
    icon: CloudIcon
  }
];

const testimonials = [
  {
    quote: "Boachie Family's custom software solution and managed services have significantly improved our operational efficiency. Their expertise in IT consulting is unmatched in Reno.",
    author: "James Q., Reno Startup Founder"
  },
  {
    quote: "The web development and cloud services provided by Boachie Family have transformed our online presence. Their team in Denver is professional, timely, and exceeded our expectations.",
    author: "Miranda D., Denver Business Owner"
  }
];

const additionalSolutions = [
  {
    title: "Network Administration",
    description: "Comprehensive network management and optimization for businesses all over the United States.",
    icon: GlobeAltIcon
  },
  {
    title: "Data Center Services",
    description: "Reliable and secure data center solutions to support your IT infrastructure needs.",
    icon: ChipIcon
  },
  {
    title: "Cybersecurity Services",
    description: "Protect your business with advanced security measures and compliance solutions.",
    icon: ShieldCheckIcon
  }
];

function Home() {
  return (
    <div>
      <section className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h1 className="text-4xl font-bold mb-2">Custom Software Solutions & Managed Services in Reno, NV & Denver</h1>
          <p className="mb-8">Elevating Your Business with Tailored IT Services, Expert Consulting, and Cloud Solutions</p>
          <Link to="/submit-request" className="bg-white text-indigo-600 font-bold py-2 px-4 rounded hover:bg-gray-100 transition duration-300">
            Get a Free Consultation
          </Link>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Our Comprehensive IT Services</h2>
          <div className="flex flex-wrap -mx-4">
            {services.map((service, index) => (
              <div key={index} className="w-full md:w-1/3 px-4 mb-8">
                <div className="border border-indigo-200 rounded-lg p-6 text-center bg-white h-full">
                  <div className="bg-indigo-100 rounded-full p-4 mx-auto mb-4 w-20 h-20 flex items-center justify-center">
                    <service.icon className="h-10 w-10 text-indigo-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-gray-100 py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Additional IT Solutions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {additionalSolutions.map((solution, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  <solution.icon className="h-6 w-6 text-indigo-600 mr-2" />
                  <h3 className="text-xl font-semibold">{solution.title}</h3>
                </div>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12">What Our Clients Are Saying</h2>
          <div className="flex flex-wrap -mx-4">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-full md:w-1/2 px-4 mb-8">
                <div className="bg-white rounded-lg p-6 shadow-md h-full">
                  <p className="mb-4">"{testimonial.quote}"</p>
                  <p className="font-semibold">- {testimonial.author}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;