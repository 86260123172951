// src/pages/About.js
import React from 'react';
import { LightBulbIcon, UserGroupIcon, CogIcon, ShieldCheckIcon, CloudIcon, SupportIcon } from '@heroicons/react/outline';

const commitments = [
  {
    title: "Custom Software Development",
    description: "Tailored solutions designed to address your unique business needs and streamline operations.",
    icon: CogIcon
  },
  {
    title: "Managed IT Services",
    description: "Comprehensive support to ensure your technology infrastructure runs smoothly and efficiently.",
    icon: SupportIcon
  },
  {
    title: "Cloud Services",
    description: "Leveraging the power of cloud technology to enhance flexibility, scalability, and security for your business.",
    icon: CloudIcon
  },
  {
    title: "Web Development",
    description: "Creating responsive, user-friendly websites that drive engagement and conversions.",
    icon: LightBulbIcon
  },
  {
    title: "IT Consulting",
    description: "Expert guidance to help you make informed decisions about your technology investments and strategy.",
    icon: UserGroupIcon
  },
  {
    title: "Cybersecurity Solutions",
    description: "Protecting your business with advanced security measures and compliance services.",
    icon: ShieldCheckIcon
  }
];

function About() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-indigo-900">About Boachie Family: Your Trusted IT Partner in Reno & Denver</h1>

      <section className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <h2 className="text-3xl font-semibold mb-6 text-indigo-800">Our Journey in Custom IT Solutions</h2>
        <div className="space-y-6">
          <p><strong className="text-indigo-600">Innovation</strong> drives our custom software development. Founded in 2024, Boachie Family emerged from a vision to provide tailored IT solutions that truly work for businesses in Reno, Nevada, and Denver, Colorado. Our journey began when a group of tech enthusiasts recognized the need for effective, customized technology solutions across various industries. This realization led us to create a company dedicated to solving complex IT challenges with innovative, custom-built solutions.</p>

          <p><strong className="text-indigo-600">Expertise</strong> in managed services and IT consulting sets us apart. At Boachie Family, we're more than just another IT company. We're a collective of problem solvers and innovators from diverse backgrounds, bringing a wealth of experience in custom software development, web services, and IT infrastructure optimization. Our team specializes in delivering comprehensive managed services and expert IT consulting to businesses of all sizes in Reno, Denver, and beyond.</p>

          <p><strong className="text-indigo-600">Solutions</strong> are at the heart of our business. We're passionate about creating and implementing answers to complex technological challenges. From developing custom websites that perfectly represent your brand to providing cloud services and ensuring robust IT security, we're committed to delivering solutions that drive real value. Our expertise in integration services allows us to streamline operations for businesses in Reno, Denver, and nationwide through remote collaboration.</p>

          <p><strong className="text-indigo-600">Empowerment</strong> through technology is our ultimate goal. As we continue to grow, our mission remains steadfast: to empower businesses across Reno, Denver, and the entire United States with custom IT solutions that foster growth, efficiency, and innovation. Whether you're a startup needing a custom software solution or an established enterprise seeking advanced cloud infrastructure, Boachie Family is here to transform your technology challenges into opportunities for success.</p>
        </div>
      </section>

      <section className="bg-white shadow-lg rounded-lg p-8 mb-8">
        <h2 className="text-3xl font-semibold mb-6 text-indigo-800">Our Commitment to Excellence</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {commitments.map((commitment, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <commitment.icon className="h-12 w-12 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{commitment.title}</h3>
              <p>{commitment.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-gray-100 p-6 rounded-lg">
        <p className="mb-4">
          At Boachie Family, we believe that technology should be a catalyst for growth and innovation. Our team is dedicated to staying at the forefront of technological advancements, ensuring that we can always offer the most effective and cutting-edge solutions to our clients.
        </p>
        <p>
          We're not just service providers; we're your technology partners. Our success is measured by the success and satisfaction of our clients. We're committed to building long-lasting relationships based on trust, reliability, and outstanding results.
        </p>
      </section>
    </div>
  );
}

export default About;