import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function SubmitRequest() {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    service: '',
    message: ''
  });

  const [charCount, setCharCount] = useState(5000);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);
  const inputRefs = useRef({});

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LevMUwqAAAAANWn09qFYnypbIc7RDUuVgbJcmTX';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'message') {
      setCharCount(5000 - value.length);
    }

    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let fieldError = '';

    switch (fieldName) {
      case 'name':
        fieldError = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'email':
        fieldError = !value.trim() ? 'Email is required' :
                     !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Please enter a valid email address' : '';
        break;
      case 'phone':
        if (value.trim() !== '' && !/^(\+\d{1,3}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)) {
          fieldError = 'Please enter a valid phone number';
        }
        break;
      case 'service':
        fieldError = value === '' ? 'Please select a service' : '';
        break;
      case 'message':
        fieldError = value.trim() === '' ? 'Message is required' : '';
        break;
      default:
        break;
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: fieldError
    }));
  };

  const validateForm = () => {
    let formIsValid = true;
    let firstErrorField = null;

    Object.keys(formData).forEach(fieldName => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName] && !firstErrorField) {
        firstErrorField = fieldName;
        formIsValid = false;
      }
    });

    if (firstErrorField) {
      scrollToTop();
      inputRefs.current[firstErrorField].focus();
    }

    return formIsValid;
  };

  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setIsLoading(true);
      try {
        const token = await executeRecaptcha();

        const dataToSend = {
          ...formData,
          recaptcha_response: token
        };

        const response = await axios.post('/submit-form', dataToSend, {
          withCredentials: true,
          headers: {'Content-Type': 'application/json'}
        });

        if (response.data.success) {
          setSubmitMessage(response.data.message || 'Your request has been submitted successfully!');
          setFormData({
            name: '',
            company: '',
            email: '',
            phone: '',
            service: '',
            message: ''
          });
          setCharCount(5000);
          setErrors({});
        } else {
          setSubmitMessage(response.data.message || 'An error occurred. Please try again later.');
        }
      } catch (error) {
        setSubmitMessage('An error occurred. Please try again later.');
        console.error('Submission error:', error);
      } finally {
        setIsSubmitting(false);
        setIsLoading(false);
        scrollToTop();

      }
    }
  };

  const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LevMUwqAAAAANWn09qFYnypbIc7RDUuVgbJcmTX', {action: 'submit'}).then(token => {
          resolve(token);
        }).catch(error => {
          reject(error);
        });
      });
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-indigo-900 fade-in">Submit a Request</h1>
      <section ref={formRef} className="bg-white shadow-lg rounded-lg p-8 mb-8 fade-in">
        {submitMessage && (
          <div className={`p-4 mb-4 rounded ${submitMessage.includes('success') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {submitMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              <span className="text-red-500">*</span> Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              ref={el => inputRefs.current.name = el}
              value={formData.name}
              onChange={handleChange}
              className={`form-input mt-1 block w-full rounded-md border-2 p-4 shadow-sm h-12 text-lg transition-all duration-300 ease-in-out ${
                errors.name ? 'border-red-500' : 'border-indigo-400 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            {errors.name && <p className="mt-1 text-sm text-red-600">{errors.name}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="form-input mt-1 block w-full rounded-md border-2 border-indigo-400 p-4 shadow-sm h-12 text-lg focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300 ease-in-out"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              <span className="text-red-500">*</span> Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              ref={el => inputRefs.current.email = el}
              value={formData.email}
              onChange={handleChange}
              className={`form-input mt-1 block w-full rounded-md border-2 p-4 shadow-sm h-12 text-lg transition-all duration-300 ease-in-out ${
                errors.email ? 'border-red-500' : 'border-indigo-400 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={`form-input mt-1 block w-full rounded-md border-2 p-4 shadow-sm h-12 text-lg transition-all duration-300 ease-in-out ${
                errors.phone ? 'border-red-500' : 'border-indigo-400 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            {errors.phone && <p className="mt-1 text-sm text-red-600">{errors.phone}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="service" className="block text-sm font-medium text-gray-700">
              <span className="text-red-500">*</span> Service Interested In
            </label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              className={`form-input mt-1 block w-full rounded-md border-2 p-2 shadow-sm h-12 text-lg transition-all duration-300 ease-in-out ${
                errors.service ? 'border-red-500' : 'border-indigo-400 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            >
              <option value="">Select a service</option>
              <option value="Custom Website Development">Custom Website Development</option>
              <option value="Systems Integration">Systems Integration</option>
              <option value="Tailored IT Solutions">Tailored IT Solutions</option>
              <option value="Other">Other</option>
            </select>
            {errors.service && <p className="mt-1 text-sm text-red-600">{errors.service}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              <span className="text-red-500">*</span> Message
            </label>
            <textarea
              id="message"
              name="message"
              ref={el => inputRefs.current.message = el}
              value={formData.message}
              onChange={handleChange}
              className={`form-textarea mt-1 block w-full rounded-md border-2 p-4 shadow-sm text-lg transition-all duration-300 ease-in-out ${
                errors.message ? 'border-red-500' : 'border-indigo-400 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
              rows="4"
              maxLength="5000"
            ></textarea>
            {errors.message && <p className="mt-1 text-sm text-red-600">{errors.message}</p>}
            <p id="charCount" className={`mt-1 text-sm ${charCount <= 100 ? 'text-red-500' : 'text-gray-500'}`}>
              {charCount} characters left
            </p>
          </div>
          <button
            type="submit"
            className="w-full inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out disabled:opacity-50"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Request'}
          </button>
        </form>
      </section>
      {isLoading && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      )}
    </div>
  );
}

export default SubmitRequest;