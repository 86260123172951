// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Services', href: '/services' },
  { name: 'About', href: '/about' },
  { name: 'Submit a Request', href: '/submit-request' },
];

function Header() {
  return (
    <Disclosure as="nav" className="bg-indigo-800 text-white">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <Link to="/" className="flex-shrink-0 text-xl font-bold flex items-center hover:opacity-80 transition-opacity">
                  <svg className="h-10 w-auto mr-3" viewBox="0 0 100 100" aria-hidden="true">
                    <circle cx="50" cy="50" r="48" fill="#f0f4f8"></circle>
                    <path d="M20 20 H80 M20 50 H80 M20 80 H80 M50 20 V80" stroke="#3b82f6" strokeWidth="2" fill="none"></path>
                    <circle cx="50" cy="50" r="30" fill="#3b82f6"></circle>
                    <circle cx="50" cy="50" r="25" fill="#f0f4f8"></circle>
                    <path d="M50 25 V35 M65 30 L60 40 M75 50 H65 M65 70 L60 60 M50 75 V65 M35 70 L40 60 M25 50 H35 M35 30 L40 40" stroke="#3b82f6" strokeWidth="4" strokeLinecap="round"></path>
                    <rect x="45" y="45" width="10" height="10" fill="#3b82f6"></rect>
                  </svg>
                  Boachie Family
                </Link>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="nav-link px-3 py-2 hover:text-gray-300"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <Disclosure.Button className="bg-indigo-800 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-white hover:bg-indigo-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Header;